import router from '@/router'
import axios from 'axios'
import QS from 'qs'
import { Toast } from 'vant'
import store from '@/store'
// 环境切换
// if (process.env.NODE_ENV === 'development') {
//   axios.defaults.baseURL = 'https://api.youtp.cn'
// } else if (process.env.NODE_ENV === 'debug') {
//   axios.defaults.baseURL = 'https://api.youtp.cn'
// } else if (process.env.NODE_ENV === 'production') {
// axios.defaults.baseURL = 'https://api.youtp.cn'
// }

// axios.defaults.baseURL = 'https://api.scyxcm.com'
// axios.defaults.baseURL = 'https://api.ahzxgp.cn'
// axios.defaults.baseURL = 'https://api.fairvote.cn'
axios.defaults.baseURL = 'https://api.scyxcm.com'
// axios.defaults.baseURL = 'https://api.yfpoll.cn'

// 设置默认请求超时时间
axios.defaults.timeout = 10000

// 添加请求拦截器
axios.interceptors.request.use(config => {
  // 每次发送请求前判断token，存在就在header加上token，果然过期所以在响应拦截器中要对返回状态进行判断
  const token = localStorage.token
  token && (config.headers.Authorization = token)
  return config
}, error => {
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 添加响应拦截器
axios.interceptors.response.use(
  response => {
    // 如果返回的状态码为200，说明接口请求成功
    if (response.status === 200) {
      if (response.data.code > 0) {
        return Promise.resolve(response)
      } else {
        Toast(response.data.msg)
        return Promise.reject(response)
      }
    } else {
      return Promise.reject(response)
    }
  },
  error => {
    if (error.response.status) {
      switch (error.response.status) {
        case 401:
          store.commit('setToken', null)
          sessionStorage.removeItem('userinfo')
          router.push({
            path: '/login'
          })
          break
        case 403:
          Toast({
            message: '登录过期，请重新登录',
            duration: 2000,
            forbidClick: true
          })
          // 清除token
          store.commit('setToken', null)
          sessionStorage.removeItem('userinfo')
          setTimeout(() => {
            router.push({
              path: '/login'
            })
          }, 1000)
          break

        default:
          break
      }
      return Promise.reject(error)
    }
  }
)

// 封装get方法
export function get (url, params) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err.data)
    })
  })
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post (url, params) {
  return new Promise((resolve, reject) => {
    axios.post(url, QS.stringify(params))
      .then(res => {
        resolve(res.data.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}
